// import React, { useState, useEffect } from "react";
// import "./preload.css"; // Ensure this file styles the preloader properly
// import preloadingGif from "./assets/preloading.gif"; // Import the preloading GIF

// const Preloader = ({ onComplete }) => {
//   const [fade, setFade] = useState(true); // Fade animation control
//   const [siteLoaded, setSiteLoaded] = useState(false); // All assets loading state
//   const [preloaderComplete, setPreloaderComplete] = useState(false); // Control when preloader is done

//   // Adjust this to match the duration of your GIF (in milliseconds)
//   const gifDuration = 8000; // For example, 5 seconds

//   // Array of image paths for home bike images
//   const homeBikeImages = Array.from({ length: 77 }, (_, i) =>
//     require(`./assets/BikeImages1/4 features_${String(i).padStart(5, '0')}.jpg`)
//   );

//   // Array of image paths for gear images
//   const gearImages = Array.from({ length: 75 }, (_, i) =>
//     require(`./assets/GearImages/Gear_${String(i).padStart(5, '0')}.png`)
//   );

//   // New image sequence (from 57 to 224)
//   const bikeSequenceImages = Array.from({ length: 168 }, (_, i) =>
//     require(`./assets/BikeSequence1/website header bike animation_${String(i + 57).padStart(5, '0')}.jpg`)
//   );

//   // Array of video paths
//   const videos = [
//     require('./assets/Full video (1).mp4'),
//     require('./assets/Light alphs.mp4'),
//     require('./assets/footer.webm')
//   ];

//   // Array of custom assets (e.g., animations, Lottie, external scripts)
//   const customAssets = [
//     // Add paths or URLs to custom assets here, e.g., Lottie animation JSONs or other third-party scripts
//   ];


//   // Preload all media: images, videos, custom assets, fonts, and models
//   useEffect(() => {
//     const loadMedia = (mediaPaths, type) => {
//       const promises = mediaPaths.map((path) => {
//         return new Promise((resolve, reject) => {
//           if (type === "image") {
//             const img = new Image();
//             img.src = path;
//             img.onload = resolve;
//             img.onerror = reject;
//           } else if (type === "video") {
//             const video = document.createElement('video');
//             video.src = path;
//             video.onloadeddata = resolve;
//             video.onerror = reject;
//           } else if (type === "custom") {
//             const customAsset = document.createElement('script');
//             customAsset.src = path;
//             customAsset.onload = resolve;
//             customAsset.onerror = reject;
//             document.body.appendChild(customAsset);
//           }
//         });
//       });
//       return Promise.all(promises);
//     };

//     // Preload critical site assets, including models
//     Promise.all([
//       loadMedia([...homeBikeImages, ...gearImages, ...bikeSequenceImages], "image"),
//       loadMedia(videos, "video"),
//       loadMedia(customAssets, "custom"),
//       document.fonts.ready,
//     ])
//       .then(() => {
//         console.log("All site assets loaded");
//         setSiteLoaded(true); // Set siteLoaded to true once everything is loaded
//       })
//       .catch((error) => console.error("Error loading site assets:", error));
//   }, [gearImages, homeBikeImages, bikeSequenceImages, videos, customAssets]);

//   // Handle fade-out effect when assets are fully loaded and the GIF has completed at least one loop
//   useEffect(() => {
//     if (siteLoaded) {
//       // Ensure preloader stays for the duration of the GIF
//       const timer = setTimeout(() => {
//         setFade(false); // Trigger fade-out effect
//         setTimeout(() => {
//           setPreloaderComplete(true); // Mark preloader as complete after the fade-out
//           onComplete(); // Preloader completes
//         },); // Wait for 2 seconds for the fade-out effect
//       }, gifDuration); // Make sure the preloader stays for at least the GIF duration

//       return () => clearTimeout(timer);
//     }
//   }, [siteLoaded, onComplete, gifDuration]);

//   return !preloaderComplete ? (
//     <div className={`preloader ${fade ? "fade-in" : "fade-out"}`}>
//       <img src={preloadingGif} alt="Loading..." className="preloading-gif" />
//     </div>
//   ) : null; // Render null when preloader is complete
// };

// export default Preloader;






// import React, { useState, useEffect } from "react";
// import * as THREE from "three";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import "./preload.css"; // Ensure this file styles the preloader properly

// const Preloader = ({ onComplete }) => {
//   const [progress, setProgress] = useState(0); // Track progress
//   const [fade, setFade] = useState(true); // Fade animation control
//   const [siteLoaded, setSiteLoaded] = useState(false); // All assets loading state
//   const [modalsLoaded, setModalsLoaded] = useState(false); // Modals loading state

//   // Array of image paths for home bike images
//   const homeBikeImages = Array.from({ length: 77 }, (_, i) =>
//     require(`./assets/BikeImages1/4 features_${String(i).padStart(5, '0')}.jpg`)
//   );
//   // Array of image paths for gear images
//   const gearImages = Array.from({ length: 75 }, (_, i) =>
//     require(`./assets/GearImages/Gear_${String(i).padStart(5, '0')}.png`)
//   );
//   // Array of video paths
//   const videos = [
//     require('./assets/Full video (1).mp4'),
//     require('./assets/Light alphs.mp4'),
//   ];
//   // Array of custom assets (e.g., animations, Lottie, external scripts)
//   const customAssets = [];

//   // Retry logic for fetch requests
//   const fetchWithRetry = (url, retries = 1, delay = 1000) => {
//     return new Promise((resolve, reject) => {
//       const attemptFetch = (n) => {
//         fetch(url)
//           .then(response => {
//             if (response.ok) {
//               return resolve(response.blob()); // Success, resolve the blob
//             } else {
//               if (n === 1) {
//                 reject(new Error(`Failed to load ${url}: ${response.statusText}`));
//               } else {
//                 setTimeout(() => attemptFetch(n - 1), delay); // Retry after delay
//               }
//             }
//           })
//           .catch(error => {
//             if (n === 1) {
//               reject(error);
//             } else {
//               setTimeout(() => attemptFetch(n - 1), delay);
//             }
//           });
//       };
//       attemptFetch(retries);
//     });
//   };

//   // Function to preload the GLB modals with progress tracking
//   const loadModals = () => {
//     const loader = new GLTFLoader();
//     loader.load(
//       '/BikeTest4.glb',
//       (gltf) => {
//         const model = gltf.scene;
//         model.scale.set(1, 1, 1); // Adjust scale if needed
//         setModalsLoaded(true); // Mark as fully loaded when the model is ready
//         console.log("Model loaded", model);
//         document.getElementById('loadingScreen').style.display = 'none'; // Hide loading screen
//         onComplete(); // Call the onComplete function after loading finishes
//       },
//       (xhr) => {
//         // Progress event to show how much is loaded
//         const loaded = (xhr.loaded / xhr.total) * 100;
//         setProgress(loaded.toFixed(2)); // Update the progress state
//       },
//       (error) => {
//         console.error("Error loading model:", error);
//       }
//     );
//   };

//   // Function to preload multiple GLB modals with retries
//   const loadOtherModals = async () => {
//     const modalUrls = [
//       '/battery.glb',
//       '/bg1.glb',
//       '/FloorMat.glb',
//       '/FloorCircle.glb'
//     ];
//     const modalPromises = modalUrls.map((url) => fetchWithRetry(url, 1));
//     try {
//       await Promise.all(modalPromises);
//       console.log("All other modals loaded successfully");
//     } catch (error) {
//       console.error("Error loading other modals:", error);
//     }
//   };

//   // Preload all media: images, videos, custom assets, fonts, and the GLB modals
//   useEffect(() => {
//     const loadMedia = (mediaPaths, type) => {
//       const promises = mediaPaths.map((path) => {
//         return new Promise((resolve, reject) => {
//           if (type === "image") {
//             const img = new Image();
//             img.src = path;
//             img.onload = resolve;
//             img.onerror = reject;
//           } else if (type === "video") {
//             const video = document.createElement('video');
//             video.src = path;
//             video.onloadeddata = resolve;
//             video.onerror = reject;
//           } else if (type === "custom") {
//             const customAsset = document.createElement('script');
//             customAsset.src = path;
//             customAsset.onload = resolve;
//             customAsset.onerror = reject;
//             document.body.appendChild(customAsset);
//           }
//         });
//       });
//       return Promise.all(promises);
//     };

//     // Preload critical site assets along with the GLB modals
//     Promise.all([
//       loadMedia([...homeBikeImages, ...gearImages], "image"),
//       loadMedia(videos, "video"),
//       loadMedia(customAssets, "custom"),
//       document.fonts.ready, // Preload fonts
//       loadModals(), // Preload the main GLB modal with progress tracking
//       loadOtherModals() // Preload the other GLB modals
//     ])
//       .then(() => {
//         console.log("All site assets loaded");
//         setSiteLoaded(true); // Set siteLoaded to true once everything is loaded
//       })
//       .catch((error) => console.error("Error loading site assets:", error));
//   }, [gearImages, homeBikeImages, videos, customAssets]);

//   // Handle fade out effect when loading finishes
//   useEffect(() => {
//     if (progress >= 100 && siteLoaded && modalsLoaded) {
//       const timer = setTimeout(() => {
//         setFade(false); // Trigger fade-out animation
//         onComplete(); // Call onComplete when finished
//       }, 1000); // Delay to allow fade effect
//       return () => clearTimeout(timer);
//     }
//   }, [progress, siteLoaded, modalsLoaded, onComplete]);

//   return (
//     <div id="loadingScreen" className="preloader">
//       <h1 className={`loading-text ${fade ? "fade-in" : "fade-out"}`}>
//         Loading {progress}% {/* Display the progress */}
//       </h1>
//     </div>
//   );
// };

// export default Preloader;


import React, { useState, useEffect } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import "./preload.css"; // Ensure this file styles the preloader properly
import Data from './assets/data.json';
import Lottie from 'lottie-react';


const Preloader = ({ onComplete }) => {
  const [count, setCount] = useState(1); // Initialize counter starting from 1
  const [fade, setFade] = useState(true); // Fade animation control
  const [assetsLoaded, setAssetsLoaded] = useState(false); // All assets loading state
  // Array of image paths for home bike images
  const homeBikeImages = Array.from({ length: 100 }, (_, i) =>
    require(`./assets/BikeImages1/1_${String(i).padStart(5, '0')}.jpg`)
  );
  // Array of image paths for gear images
  const gearImages = Array.from({ length: 94 }, (_, i) =>
    require(`./assets/GearImages2/Gear_${String(i).padStart(5, '0')}.jpg`)
  );

   // New image sequence (from 57 to 224)
   const bikeSequenceImages1 = Array.from({ length: 168 }, (_, i) =>
    require(`./assets/BikeSequence2/1_${String(i + 57).padStart(5, '0')}.jpg`)
   );
  // Array of video paths
  const videos = [
    require('./assets/Full video (1).mp4'),
    require('./assets/Light alphs.mp4'),
  ];
  // Function to preload the GLB modal
  const loadModals = () => {
    const loader = new GLTFLoader();
    loader.load(
      '/BatteryOriginal.glb',
      (gltf) => {
        const model = gltf.scene;
        model.scale.set(1, 1, 1); // Adjust scale if needed
        console.log("Model loaded");
      },
      null,
      (error) => {
        console.error("Error loading model:", error);
      }
    );
  };
  // Function to preload media assets (images, videos)
  const preloadAssets = async () => {
    const loadMedia = (mediaPaths, type) => {
      const promises = mediaPaths.map((path) => {
        return new Promise((resolve, reject) => {
          if (type === "image") {
            const img = new Image();
            img.src = path;
            img.onload = resolve;
            img.onerror = reject;
          } else if (type === "video") {
            const video = document.createElement('video');
            video.src = path;
            video.onloadeddata = resolve;
            video.onerror = reject;
          }
        });
      });
      return Promise.all(promises);
    };
    try {
      await Promise.all([
        loadMedia([...homeBikeImages, ...gearImages,...bikeSequenceImages1], "image"),
        loadMedia(videos, "video"),
        loadModals() // Preload the main GLB modal
      ]);
      setAssetsLoaded(true); // Mark assets as loaded
    } catch (error) {
      console.error("Error loading assets:", error);
    }
  };
  // Simulate count increase from 1 to 231 over time
  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount < 100) {
          return prevCount + 1;
        } else {
          clearInterval(timer);
          return prevCount;
        }
      });
    }, 100); // Adjust the interval to control the speed (20 ms ~ 4.6 seconds total)
  }, []);
  // Hide the preloader after count reaches 231 and assets are fully loaded
  useEffect(() => {
    if (count >= 100 && assetsLoaded) {
      setTimeout(() => {
        setFade(false); // Trigger fade-out animation
        onComplete(); // Call onComplete to show the site
      }, 500); // Optional delay for smoother transition
    }
  }, [count, assetsLoaded, onComplete]);
  // Start loading assets when component mounts
  useEffect(() => {
    preloadAssets();
  }, []);
  return (
    <div id="loadingScreen" className="preloader">
      <img src={require('./assets/1400.gif')} alt="Loading" className="loading-image" />
      <h1 className={`loading-text ${fade ? "fade-in" : "fade-out"}`}>
        {count} 
      </h1>
    </div>
  );
};
export default Preloader;

