// import React, { useState, useEffect, useRef } from "react";
// import "./Features.css";

// // Function to dynamically import images
// function importAll(r) {
//   return r.keys().map(r);
// }

// const images = importAll(
//   require.context("./assets/BikeImages", false, /\.(png|jpe?g|svg)$/)
// );

// const BikeAnimation = () => {
//   const [currentImageIndex, setCurrentImageIndex] = useState(0); // Start with the 0th image
//   const [activeEdgePoint, setActiveEdgePoint] = useState(0); // Track active edge point
//   const [transitioning, setTransitioning] = useState(false); // State to track if transitioning
//   const [scrolling, setScrolling] = useState(false); // To manage scroll events
//   const [lockScroll, setLockScroll] = useState(true); // Lock scroll initially
//   const imgRef = useRef(null); // Reference to the image element

//   const edgePoints = [
//     { start: 0, end: 0 }, // First image
//     { start: 1, end: 33 }, // Images 1-33 (first phase)
//     { start: 34, end: 50 }, // Images 34-50 (second phase)
//     { start: 51, end: 70 }, // Images 51-70 (third phase)
//   ];

//   // Preload all images at once on component mount
//   const preloadImages = () => {
//     images.forEach((src) => {
//       const img = new Image();
//       img.src = src;
//     });
//   };

//   useEffect(() => {
//     preloadImages(); // Preload all images when the component mounts
//   }, []);

//   // Smooth image rendering function
//   const changeImage = (startIndex, endIndex) => {
//     if (transitioning) return; // Prevent transition overlap
//     setTransitioning(true); // Start transition
//     const step = startIndex < endIndex ? 1 : -1; // Determine the step for transition
//     const loopEnd = startIndex < endIndex ? endIndex + 1 : endIndex - 1; // Define the loop end

//     // Loop through each index from startIndex to endIndex
//     for (let index = startIndex; index !== loopEnd; index += step) {
//       setTimeout(() => {
//         setCurrentImageIndex(index);

//         // Check if imgRef is available before setting the src property
//         if (imgRef.current) {
//           imgRef.current.src = images[index]; // Update the image source directly
//         }
//       }, Math.abs(index - startIndex) * 70); // Adjust the speed of the transition
//     }

//     // Reset transitioning state after the last image
//     setTimeout(
//       () => setTransitioning(false),
//       Math.abs(endIndex - startIndex + 1) * 50
//     );
//   };

//   // Scroll handler to manage scroll between edge points
//   const handleScrolls = (e) => {
//     e.preventDefault();
//     const delta = e.deltaY;

//     if (!transitioning && lockScroll) {
//       if (delta > 0 && activeEdgePoint < edgePoints.length - 1) {
//         // Scroll down logic
//         const { end } = edgePoints[activeEdgePoint + 1];
//         changeImage(currentImageIndex, end);
//         setActiveEdgePoint((prev) => prev + 1);

//         if (
//           activeEdgePoint === edgePoints.length - 2 &&
//           currentImageIndex >= 70
//         ) {
//           setLockScroll(false); // Unlock scroll after the final phase
//         }
//       } else if (delta < 0 && activeEdgePoint > 0) {
//         // Scroll up logic
//         const { start } = edgePoints[activeEdgePoint - 1];
//         changeImage(currentImageIndex, start);
//         setActiveEdgePoint((prev) => prev - 1);
//       }
//     }
//   };

//   // Attach scroll event
//   useEffect(() => {
//     const handleScrollEvent = (event) => {
//       if (!scrolling) {
//         setScrolling(true);
//         handleScrolls(event);
//         setTimeout(() => setScrolling(false), 200);
//       }
//     };

//     if (lockScroll) {
//       window.addEventListener("wheel", handleScrollEvent, { passive: false });
//     } else {
//       window.removeEventListener("wheel", handleScrollEvent);
//     }

//     return () => {
//       window.removeEventListener("wheel", handleScrollEvent);
//     };
//   }, [activeEdgePoint, currentImageIndex, transitioning, scrolling, lockScroll]);
//   return (
//     <div className="bikefeature-container" style={{ height: "100vh" }}>
//     <div className="bike-flex">
//       <div className="bike-placing">
//         <img
//           src={images[currentImageIndex]} // Use preloaded images
//           className="Bikefront-images"
//           alt="Bike Animation"
//           ref={imgRef}
//         />
//       </div>

//       {/* Show bike-text-container based on current image index */}
//       {currentImageIndex === 0 && (
//         <div className="bike-text-container">
//           <div className="bike-box">
//             <div className="bike-icon"></div>
//             <h5 className="bike-information">Regenerative Braking</h5>
//             <p className="bike-details">
//               Enhances Energy
//               <br /> Efficiency.
//             </p>
//           </div>
//         </div>
//       )}
//       {currentImageIndex === 33 && (
//         <div className="bike-text-container">
//           <div className="bike-box1">
//             <div className="bike-icon"></div>
//             <h5 className="bike-information">New Feature</h5>
//             <p className="bike-details">Details about feature.</p>
//           </div>
//         </div>
//       )}
//       {currentImageIndex === 50 && (
//         <div className="bike-text-container">
//           <div className="bike-box2">
//             <div className="bike-icon"></div>
//             <h5 className="bike-information">Another Feature</h5>
//             <p className="bike-details">More details here.</p>
//           </div>
//         </div>
//       )}
//       {currentImageIndex === 70 && (
//         <div className="bike-text-container">
//           <div className="bike-box3">
//             <div className="bike-icon"></div>
//             <h5 className="bike-information">Final Feature</h5>
//             <p className="bike-details">Summary of features.</p>
//           </div>
//         </div>
//       )}
//     </div>
//   </div>
//   );
// };

// export default BikeAnimation;
import React, { useState, useRef, useEffect } from "react";
import "./Features.css";
import Line from './assets/Line.svg'; // Update with your image path

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("./assets/BikeImages1", false, /\.(png|jpe?g|svg)$/)
);

const edgePoints = [
  { start: 0, end: 0 },
  { start: 1, end: 35 },
  { start: 36, end: 55 },
  { start: 56, end: 100 },
];

const BikeAnimation = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(7);
  const [activeEdgePoint, setActiveEdgePoint] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [transitioning, setTransitioning] = useState(false);
  const imgRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    if (isPlaying && !transitioning) {
      animationRef.current = setTimeout(() => {
        autoPlayImages();
      }, 50); // Adjust the speed of image transition here
    }

    return () => {
      clearTimeout(animationRef.current);
    };
  }, [currentImageIndex, isPlaying, transitioning]);

  const autoPlayImages = () => {
    // Get the current screen width
    const screenWidth = window.innerWidth;
  
    if (screenWidth >= 320 && screenWidth <= 768) {
      setCurrentImageIndex(0); 
      return; 
    }
  
    if (transitioning) return;
  
    const currentPoint = edgePoints[activeEdgePoint];
  
    if (currentImageIndex === currentPoint.end) {
      setTransitioning(true);
  
      setTimeout(() => {
        setTransitioning(false);
        setActiveEdgePoint((prev) => {
          const newActivePoint = prev + 1;
          return newActivePoint >= edgePoints.length ? 0 : newActivePoint;
        });
      }, 2000);
    } else {
      setCurrentImageIndex((prev) => {
        const nextIndex = prev + 1;
        return nextIndex > currentPoint.end ? currentPoint.end : nextIndex;
      });
    }
  };
  

  const handleEdgePointClick = async (pointIndex) => {
    clearTimeout(animationRef.current);
    setIsPlaying(false);
    setTransitioning(true);

    const currentPoint = edgePoints[pointIndex];
    const step = currentImageIndex < currentPoint.end ? 1 : -1;

    const transitionToEdgePoint = async () => {
      for (let i = currentImageIndex; i !== currentPoint.end + step; i += step) {
        await new Promise((resolve) => {
          setTimeout(() => {
            setCurrentImageIndex(i);
            resolve();
          }, 50);
        });
      }
    };

    await transitionToEdgePoint();
    setTransitioning(false);
    setTimeout(() => {
      setIsPlaying(true);
    }, 2000);

    setActiveEdgePoint(pointIndex);
  };

  // Calculate the progress bar width based on active edge point
  const calculateProgressBarWidth = () => {
    const totalPoints = edgePoints.length - 1; // Total number of segments
    const segmentWidth = 100 / totalPoints; // Width for each segment

    return `${activeEdgePoint * segmentWidth}%`; // Width based on active edge point
  };

  return (
    <>
      <div className="bike-flex">
        <div className="bike-placing">
          <img
            src={images[currentImageIndex]}
            className="Bikefront-images"
            alt="Bike Animation"
            ref={imgRef}
          />
        </div>

        {/* Timeline with progress and edge points */}
        <div className="">
          <div className=""></div>

          <div
            className=""
            style={{ width: calculateProgressBarWidth() }}
          ></div>

          {edgePoints.map((point, index) => (
            <div
              key={index}
              className={` ${index === activeEdgePoint ? 'active' : ''}`}
              onClick={() => handleEdgePointClick(index)}
              style={{
                left: `${(index / (edgePoints.length - 1)) * 100}%`, // Position edge points evenly
              }}
            >
              {currentImageIndex >= point.end && (
                <div className=""></div>
              )}
            </div>
          ))}
        </div>

        {currentImageIndex === 0 && (
          <div className={`bike-text-container`}>
            {/* <div className="blinking-dot-container"></div>
            <div className="blinking-dot"></div> */}
            <div className={`bike-box ${currentImageIndex === 0 ? "show" : ""}`}>
              <h5 className="bike-information">Regenerative Braking</h5>
              <p className="bike-details">
                Enhances Energy Efficiency.
              </p>
            </div>
          </div>
        )}

        {currentImageIndex === 35 && (
          <div className="bike-text-container">
            {/* <div className="blinking-dot-container1"></div>
            <div className="blinking-dot1"></div> */}
            <div className={`bike-box1 ${currentImageIndex === 35 ? "show" : ""}`}>
              <h5 className="bike-information">Hill Hold Control</h5>
              <p className="bike-details">
                Keeps the bike stationary <br />on inclines, preventing rollback.
              </p>
            </div>
          </div>
        )}

        {currentImageIndex === 55 && (
          <div className="bike-text-container">
             {/* <div className="blinking-dot-container2"></div>
            <div className="blinking-dot2"></div> */}
            <div className={`bike-box2 ${currentImageIndex === 55 ? "show" : ""}`}>
              <h5 className="bike-information">Traction Control</h5>
              <p className="bike-details">
                Manages Wheel Slip, ensuring better grip <br />and stability especially in slippery conditions.
              </p>
            </div>
          </div>
        )}

        {currentImageIndex === 100 && (
          <div className="bike-text-container">
            {/* <div className="blinking-dot-container3"></div>
            <div className="blinking-dot3"></div> */}
            <div className={`bike-box3 ${currentImageIndex === 100 ? "show" : ""}`}>
              <h5 className="bike-information">Park Assist & Reverse Throttle</h5>
              <p className="bike-details">
                Assists in Parking and maneuvering.
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BikeAnimation;

