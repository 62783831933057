import React, { useState, useEffect, useRef } from 'react';
import './bikeSequence.css';
import Videosource from './assets/Light alphs.mp4';
import Videosource1 from './assets/top loop (1).mp4';

function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(
    require.context('./assets/BikeSequence2', false, /\.(png|jpe?g|svg)$/)
);

function Bikesequence() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
    const [videoOpacity, setVideoOpacity] = useState(1); // Start with full opacity
    const bikeContainerRef = useRef(null);
    const bike1Ref = useRef(null);
    const videoRef = useRef(null);

    // Update mobile view on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const bikeContainer = bikeContainerRef.current;

        if (isMobileView) {
            // For mobile, set the height to 100vh
            if (bikeContainer) {
                bikeContainer.style.height = '25vh';
            }
        } else {
            // For desktop, keep the scroll effect and larger height
            if (bikeContainer) {
                bikeContainer.style.height = '300vh'; // Adjust this based on your needs
            }

            const gearElement = bike1Ref.current;
            if (gearElement) {
                gearElement.style.position = 'sticky';
                gearElement.style.top = '0';
            }

            const handleScroll = () => {
                const gearContainerTop = bikeContainer.offsetTop;
                const gearContainerHeight = bikeContainer.offsetHeight;
                const windowHeight = window.innerHeight;
                const scrollPosition = window.scrollY;
                const totalImages = images.length;

                const scrollableHeight = gearContainerHeight - windowHeight;
                const startScroll = gearContainerTop;
                const endScroll = gearContainerTop + scrollableHeight;

                if (scrollPosition >= startScroll && scrollPosition <= endScroll) {
                    const progress = (scrollPosition - startScroll) / scrollableHeight;
                    const imageIndex = Math.floor(progress * totalImages);
                    setCurrentImageIndex(Math.min(Math.max(imageIndex, 0), totalImages - 1));

                    // Gradually reduce video opacity as scroll progresses
                    if (imageIndex === 0) {
                        setVideoOpacity(1 - progress * 2); // Adjust to control fading speed
                    }
                }
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [isMobileView]);

    // For mobile view, always show the last image (224th)
    const mobileImageIndex = images.length - 1;

    return (
        <>
            <div className="bikeSequence-container" ref={bikeContainerRef}>
                <div className="bikeSequence" ref={bike1Ref}>
                    {isMobileView ? (
                        // Only show the last image and no video on mobile view
                        <img
                            src={images[mobileImageIndex]?.default || images[mobileImageIndex]}
                            className="bikeSequence-pic"
                            alt={`bike ${mobileImageIndex}`}
                        />
                    ) : (
                        <>
                            {currentImageIndex === 0 && (
                                <video
                                    ref={videoRef}
                                    src={Videosource1}
                                    loop
                                    autoPlay
                                    muted
                                    className="bikeSequence-video"
                                    style={{ opacity: videoOpacity }}
                                />
                            )}
                            <img
                                src={images[currentImageIndex]?.default || images[currentImageIndex]}
                                className="bikeSequence-pic"
                                alt={`bike ${currentImageIndex}`}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Bikesequence;
